<script>
// Esta vista esta incompleta. No utilizar hasta completar

export default {
  name: 'CrearCargoV2',

  mounted() {
    // this.$store.getters.fetchGet({ path: `Cargo/GetTipoParaNuevoCargo/${this.empresaId}`})
  
  },
  computed: {
    empresaId() {
      const {empresa} = JSON.parse(localStorage.getItem('setPersonaAct'));
      return empresa.id;
    }

  },

  methods: {
    async crearCargo(event) {
      const fields = Object.fromEntries(new FormData(event.target).entries() );
      console.log(fields);

      // this.$store.getters.fetchPost({ path: "Cargo/NuevoCargo", fields })
    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <b-card>
      <form @submit.prevent="crearCargo">
        <div>
          <p class="title">Datos del nuevo cargo</p>
        </div>

        <div class="wrapper-fields">

          <div class="wrapper-input" title="Nombre de la empresa">
            <label for="nombre">Nombre</label>
            <b-form-input id="nombre" name="nombre" required></b-form-input>
          </div>

          <div class="wrapper-input" title="Nivel de cargo">
            <label for="tipoCargoId">Nivel de cargo</label>
            <HelexSelect id="tipoCargoId" name="tipoCargoId" :options="[]" optionLabel="name" optionValue="id"></HelexSelect>
          </div>

          <div class="wrapper-input" title="Cargo superior">
            <label for="cargoDependienciaId">Cargo superior</label>
            <HelexSelect id="cargoDependienciaId" name="cargoDependienciaId" :options="[]" optionLabel="name" optionValue="id"></HelexSelect>
          </div>

          <div class="wrapper-input" title="Sucursal">
            <label for="sucursalId">Sucursal</label>
            <HelexSelect id="sucursalId" name="sucursalId" :options="[]" optionLabel="name" optionValue="id"></HelexSelect>
          </div>
          <div class="wrapper-input" title="Departamento">
            <label for="departamentoId">Departamento</label>
            <HelexSelect id="departamentoId" name="departamentoId" :options="[]" optionLabel="name" optionValue="id"></HelexSelect>
          </div>
          <div class="wrapper-input" title="Area">
            <label for="areaId">Area</label>
            <HelexSelect id="areaId" name="areaId" :options="[]" optionLabel="name" optionValue="id"></HelexSelect>
          </div>

        </div>
      </form>
    </b-card>
  </div>
</template>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;

}

.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  grid-column: span 2;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}


.form {
  padding: 0 48px 16px;
  display: flex;
  flex-direction: column;
}

.form .button {
  align-self: flex-end;
}
</style>